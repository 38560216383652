import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllCities, resetState } from "store/slices/filtersSlice";

function FilterByCity() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.filters.loading);
  const provinces = useSelector(
    (state) => state?.filters?.cities?.provinces || []
  );
  const cities = provinces?.flatMap(
    (province) => province?.cities?.map((cityObj) => cityObj.city) || []
  );
  const [countriesArray] = useState([
    { code: "PK", label: "Pakistan" },
    { code: "SA", label: "KSA" },
  ]);
  const [selectedCountry, setSelectedCountry] = useState(countriesArray[1]);

  useEffect(() => {
    dispatch(getAllCities(selectedCountry?.code));
    return () => dispatch(resetState());
  }, [dispatch, selectedCountry?.code]);

  return (
    <Box display="flex" gap={1}>
      <Autocomplete
        sx={{
          minWidth: 170,
          maxWidth: 180,
        }}
        size="small"
        value={selectedCountry}
        onChange={(_, newValue) => {
          if (newValue) {
            setSelectedCountry(newValue);
          }
        }}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        noOptionsText="No Options Available"
        options={countriesArray}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Country"
            fullWidth
            sx={{
              "& .MuiInputLabel-root": { fontSize: "1.063rem", height: 44 },
              "& .MuiInputBase-root": { height: 44 },
            }}
          />
        )}
      />

      <Autocomplete
        sx={{
          minWidth: 170,
          maxWidth: 180,
        }}
        size="small"
        getOptionLabel={(city) => city}
        isOptionEqualToValue={(option, value) => option.city === value.city}
        noOptionsText="No Options Available"
        options={cities}
        onChange={(_, newValue) => {
          navigate(newValue ? `/stores?city=${newValue}` : `/stores`);
        }}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select City"
            fullWidth
            sx={{
              "& .MuiInputLabel-root": { fontSize: "1.063rem", height: 44 },
              "& .MuiInputBase-root": { height: 44 },
            }}
          />
        )}
      />
    </Box>
  );
}

export default memo(FilterByCity);
