import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import StyledGrid from "components/StyledGrid";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getOrdersFees, getTransactions } from "store/slices/reportSlice";
import { CURRENCY } from "utils/constants/constants";
import useQueryParams from "utils/hooks/useQueryParams";

function ReportDataGrid() {
  const dispatch = useDispatch();
  const params = useQueryParams();
  const { results, totalPages, loading, orders, vmtTab } = useSelector(
    (state) => state.reports
  );
  const userRole = useSelector((state) => state.auth?.userRole);

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return date.toISOString().split("T")[0];
  };

  useEffect(() => {
    if (vmtTab === "ORDERSFEES") {
      dispatch(getOrdersFees(params));
    } else {
      if (
        new Date().toDateString() === params.from &&
        new Date().toDateString() === params.to
      ) {
        dispatch(
          getTransactions({
            from: params.from,
            limit: params.limit,
            page: params.page,
          })
        );
      } else {
        dispatch(getTransactions(params));
      }
    }
  }, [dispatch, params, vmtTab]);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: "date",
        headerName: "Date",
        minWidth: 100,
        flex: 0.6,
        renderCell: (params) => (
          <Typography>{formatDate(params.row.createdAt)}</Typography>
        ),
      },
      {
        field: "refNo",
        headerName: "Ref No",
        minWidth: 120,
        flex: 1.25,
        renderCell: (params) => (
          <Link
            to={
              userRole === "admin"
                ? `/orders/full-orders/${params?.row?.orderDetailId}`
                : `/orders/${params?.row?.orderId}`
            }
            style={{
              textDecoration: "none",
              color: "black",
              display: "block",
              maxWidth: "100%",
            }}
          >
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                display: "block",
                cursor: "pointer",
                maxWidth: "100%",
                paddingY: "15px",
              }}
            >
              {params?.row?.orderId ?? params?.row?._id}
            </Typography>
          </Link>
        ),
      },
      {
        field: "transaction",
        headerName: "Transaction",
        minWidth: 125,
        flex: 0.6,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params.row.method}
          </Typography>
        ),
      },
      {
        field: "shippingCharges",
        headerName: "Shipping Charges",
        minWidth: 125,
        flex: 0.8,
        renderCell: (params) => (
          <Typography>
            {CURRENCY} {params.row.adjusttedShipment ?? 0}
          </Typography>
        ),
      },
      {
        field: "premium",
        headerName: "Premium Amount",
        minWidth: 125,
        flex: 0.8,
        renderCell: (params) => (
          <Typography>
            {CURRENCY} {params.row.premiumAmount ?? 0}
          </Typography>
        ),
      },
      {
        field: "forex",
        headerName: "Forex",
        minWidth: 125,
        flex: 0.5,
        renderCell: (params) => (
          <Typography>
            {CURRENCY} {params.row.forex ?? 0}
          </Typography>
        ),
      },
      {
        field: "vat",
        headerName: "VAT",
        minWidth: 125,
        flex: 0.4,
        renderCell: (params) => (
          <Typography>
            {CURRENCY} {params.row.vat ?? 0}
          </Typography>
        ),
      },
      {
        field: "amount",
        headerName: "Amount",
        minWidth: 90,
        flex: 0.6,
        renderCell: (params) => (
          <Typography>
            {CURRENCY} {params?.row?.basePrice?.toFixed(2) ?? 0}
          </Typography>
        ),
      },
    ];

    const orderColumns = [
      {
        field: "orderNo",
        headerName: "Order No",
        minWidth: 150,
        flex: 1.25,
        renderCell: (params) => (
          <Link
            to={`/orders/full-orders/${params?.row?._id}`}
            style={{
              textDecoration: "none",
              color: "black",
              display: "block",
              maxWidth: "100%",
            }}
          >
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                display: "block",
                paddingY: "15px",
              }}
            >
              {params.row._id}
            </Typography>
          </Link>
        ),
      },
      {
        field: "orderDate",
        headerName: "Order Date",
        minWidth: 125,
        flex: 0.8,
        renderCell: (params) => (
          <Typography textTransform="capitalize" sx={{ paddingY: "15px" }}>
            {formatDate(params.row.createdAt)}
          </Typography>
        ),
      },
      {
        field: "commission",
        headerName: "Commission",
        minWidth: 90,
        flex: 1,
        renderCell: (params) => (
          <Typography>
            {CURRENCY} {params?.row?.commission ?? 0}
          </Typography>
        ),
      },
      {
        field: "quantity",
        headerName: "Quantity",
        minWidth: 90,
        flex: 0.7,
        renderCell: (params) => (
          <Typography>{params?.row?.quantity ?? 0}</Typography>
        ),
      },
      {
        field: "total",
        headerName: "Total",
        minWidth: 90,
        flex: 1,
        renderCell: (params) => (
          <Typography>
            {CURRENCY} {params?.row?.total ?? 0}
          </Typography>
        ),
      },
    ];

    if (vmtTab === "INVOICED") {
      baseColumns.splice(3, 0, {
        field: "commission",
        headerName: "Commission",
        minWidth: 90,
        flex: 0.6,
        renderCell: (params) => (
          <Typography>
            {CURRENCY} {(Number(params?.row?.commission) || 0).toFixed(2)}
          </Typography>
        ),
      });
    }

    return vmtTab === "ORDERSFEES" ? orderColumns : baseColumns;
  }, [userRole, vmtTab]);

  const rows = vmtTab === "ORDERSFEES" ? orders : results;

  return (
    <Box>
      <StyledGrid
        rows={rows ?? []}
        loading={loading}
        columns={columns}
        totalPages={totalPages}
        customRowId={vmtTab === "ORDERSFEES" ? "id" : "_id"}
        checkboxSelection={false}
        transactionGrid={true}
      />
    </Box>
  );
}

export default ReportDataGrid;
