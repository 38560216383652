import { Box, Grid, Typography } from "@mui/material";
import AliExpressDataGrid from "./AliExpressDataGrid";
import CheckboxContent from "./CheckboxContent";

function AliExpressImports() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        fontWeight={600}
        marginY={4}
      >
        Ali Express Products
      </Typography>
      <Grid container spacing={2} sx={{ height: "100%", flex: 1 }}>
        <Grid
          item
          md={3}
          sx={{
            height: "90vh",
            overflowY: "auto",
            marginTop: 12,
          }}
        >
          <Box sx={{ height: "100%" }}>
            <CheckboxContent />
          </Box>
        </Grid>
        <Grid item md={9}>
          <AliExpressDataGrid />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AliExpressImports;
