import { Autocomplete, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { FILTER_TEXT_COLOR, FILTERS_BACKGROUND_COLOR } from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";

const addOnTypes = {
  ADDON: "addOn",
  CANCELORDER: "cancelOrder",
  PLATFORM: "platform",
};

function AddOnTypeAutocomplete() {
  const params = useQueryParams();
  const navigate = useNavigate();
  const [selectedAddOnType, setSelectedAddOnType] = useState(null);

  const addOnOptions = Object.keys(addOnTypes).map((key) => ({
    label: addOnTypes[key],
    value: key,
  }));

  const handleChange = useCallback(
    (_, newValue) => {
      let _params = { ...params };

      if (newValue?.label) _params.addOnType = newValue?.label;
      else if (!newValue && _params?.addOnType) {
        delete _params.addOnType;
      }
      setSelectedAddOnType(newValue);
      navigate({
        search: createSearchParams(_params).toString(),
      });
    },
    [navigate, params]
  );

  return (
    <Autocomplete
      sx={{ minWidth: 170, maxWidth: 180 }}
      size="small"
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      options={addOnOptions}
      value={selectedAddOnType}
      noOptionsText="No Options Available"
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Add-On Type"
          fullWidth
          sx={{
            "& .MuiInputLabel-root": {
              fontSize: "1.063rem",
              height: 44,
            },
            "& .MuiInputBase-root": {
              height: 44,
              backgroundColor: FILTERS_BACKGROUND_COLOR,
              cursor: "pointer",
            },
            input: {
              color: FILTER_TEXT_COLOR,
              "&::placeholder": {
                opacity: 1,
              },
            },
          }}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}

export default AddOnTypeAutocomplete;
