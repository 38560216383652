import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import LanguageToggleButtons from "components/LanguageToggleButtons";
import { Form, FormikProvider, useFormik } from "formik";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { resetLanguageState } from "store/slices/languageTranslatorSlice";
import { getProductById, updateProduct } from "store/slices/productsSlice";
import { getAdminSellerDetailById } from "store/slices/storesSlice";
import { volumeUnits } from "utils/constants/productForm";
import parseEditorValue from "utils/helpers/parseEditorValue";
import {
  createProductApiData,
  normalizeAttributes,
} from "utils/helpers/productForm";
import reduceObject from "utils/helpers/reduceObject";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";
import BasicInformationSection from "./BasicInformationSection";
import DeliverySection from "./DeliverySection";
import DescriptionSection from "./DescriptionSection";
import MediaSection from "./MediaSection";
import PriceSection from "./PriceSection";
import PricingSummary from "./PricingSummary";
import ProductActiveStatusSection from "./ProductActiveStatusSection";
import ProductFormTabs from "./ProductFormTabs";
import ProductLocation from "./ProductLocation";
import ProductOptionsSections from "./ProductOptionsSections";
import ServiceSection from "./ServiceSection";
import StockControlSection from "./StockControlSection";

const attributesList = [
  "Size",
  "Color",
  "Waist",
  "Pieces",
  "Pack",
  "Length",
  "Height",
  "Width",
  "Litre",
  "ML",
  "Weight",
  "RAM",
  "ROM",
  "Strap",
  "mAh",
];

const EditProductForm = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.products?.productLoading);
  const product = useSelector((state) => state.products?.product);
  const [data, setData] = useState(null);
  const [showNextButton, setShowNextButton] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const flattenedCategories = useSelector(
    (state) => state.filters.flattenedCategories
  );
  const translatedData = useSelector(
    (state) =>
      state?.languageTranslation?.translatedData ??
      state?.products?.product?.lang?.ar ??
      null
  );
  const selectedLanguage = useSelector((state) => state.UI?.language ?? "En");

  const initialValues = useMemo(
    () => ({
      // Basic Information
      id: product?.id ?? "",
      name:
        selectedLanguage === "Ar"
          ? translatedData?.productName
          : product?.productName ?? "",
      sku: product?.sku,
      category:
        flattenedCategories?.find(
          (ctg) => ctg?.value === product?.category?.id
        ) ?? null,
      hsnCode: product.hsnCode ?? "",

      // Location
      origin: product?.origin ?? "",
      region: product?.region ?? [],

      // Active Status
      active: product?.active ?? false,

      // Pricing
      price: product?.regularPrice ?? 0,
      onSale: product?.onSale ?? false,
      salePrice: product?.salePrice ?? 0,

      // Description
      description:
        selectedLanguage === "Ar"
          ? parseEditorValue(translatedData?.description)
          : product?.description
          ? parseEditorValue(product?.description)
          : "",

      // Stock Control
      quantity: product?.quantity ?? "",

      // Product Attributes
      attributes: product?.attributes?.length
        ? normalizeAttributes(product?.attributes, attributesList)
        : [],

      // Media
      mainImage: product?.mainImage ?? "",
      gallery: product?.gallery ?? [],
      video: product?.videoUrl ?? "",

      // Delivery
      packageVolumeUnit:
        volumeUnits.find(
          (unit) => unit === product?.packageInfo?.volume?.unit
        ) ?? "",
      packageHeight: product?.packageInfo?.volume?.height ?? "",
      packageWidth: product?.packageInfo?.volume?.width ?? "",
      packageLength: product?.packageInfo?.volume?.length ?? "",
      packageWeightUnit: "kg",
      packageWeight: product?.packageInfo?.weight ?? "",

      // Service
      warrantyType: product?.warranty?.warrantyType ?? "none",
      warrantyPeriod: product?.warranty?.warrantyPeriod?.unit ?? "",
      warrantyTenure: product?.warranty?.warrantyPeriod?.tenure ?? 0,
    }),
    [selectedLanguage, flattenedCategories, product, translatedData]
  );

  useEffect(() => {
    if (selectedLanguage === "Ar") {
      setData({
        text: {
          productName: product?.productName,
          description: product?.description,
        },
        lang: "ar",
      });
    }
  }, [dispatch, product, selectedLanguage]);

  useEffect(() => {
    if (product.sellerDetail) {
      dispatch(getAdminSellerDetailById(product.sellerDetail));
    }
  }, [dispatch, product]);

  const handleSubmit = useCallback(
    async (values, { setSubmitting, resetForm }) => {
      setShowNextButton(() => false);
      const updatedValues = reduceObject(values, initialValues);

      // Early return if no changes
      if (!Object.keys(updatedValues).length) {
        toast.info("No changes have been made");
        if (!values.attributes.length) {
          navigate("/products");
        }
        return;
      }

      // Check if only excluded fields are updated
      const excludedFields = ["mainImage", "gallery", "video"];
      const isExcludedUpdate = Object.keys(updatedValues).every((key) =>
        excludedFields.includes(key)
      );

      if (isExcludedUpdate) {
        await dispatch(getProductById(params?.id));
        toast.success("Product media automatically updated on uploading");
        resetForm(); // Reset the form to initial values

        return; // Exit without calling the API
      }

      // Construct warranty object
      const warrantyType = values?.warrantyType;

      const warranty =
        updatedValues?.warrantyType ||
        updatedValues?.warrantyUnit ||
        updatedValues?.warrantyTenure
          ? warrantyType === "none"
            ? { warrantyType }
            : ["seller", "brand"].includes(warrantyType)
            ? {
                warrantyType,
                warrantyPeriod: {
                  unit: values?.warrantyPeriod,
                  tenure: values?.warrantyTenure,
                },
              }
            : { warrantyType }
          : undefined;

      // Prepare final API data
      const data = createProductApiData(
        {
          ...updatedValues,
          ...(updatedValues?.name || updatedValues?.description
            ? { name: values?.name, description: values?.description }
            : {}),
          warranty,
        },
        selectedLanguage === "Ar"
      );
      try {
        // Dispatch update product action
        await dispatch(
          updateProduct({ productId: values.id, body: data })
        ).unwrap();
        resetForm();
        if (!initialValues.attributes.length && values.attributes.length) {
          navigate({
            search: createSearchParams({ tab: "variants" }).toString(),
          });
          window.scrollTo(0, 0);
        } else if (!values.attributes?.length) navigate("/products");
        else setShowNextButton(() => true);
      } finally {
        setSubmitting(false);
      }
    },
    [initialValues, selectedLanguage, navigate, dispatch, params?.id]
  );

  const handleClickNext = useCallback(() => {
    navigate({
      search: createSearchParams({ tab: "variants" }).toString(),
    });
  }, [navigate]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  useEffect(
    () => () => {
      dispatch(resetLanguageState());
      setShowNextButton(() => false);
    },
    [dispatch]
  );

  const handleFormSubmit = () => {
    setShowErrorMessage(false);
    if (!formik.isValid) {
      setShowErrorMessage(true);
    }
    formik.handleSubmit();
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <ProductFormTabs />
        <LanguageToggleButtons data={data} />
      </Box>
      <FormikProvider value={formik}>
        <Form
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
            }
          }}
        >
          <Grid container spacing={3}>
            {/* Basic Information Section */}
            <Grid item xs={12} lg={7.5}>
              <BasicInformationSection />
              <ProductLocation />
            </Grid>

            {/* Pricing Section */}
            <Grid item xs={12} lg={4.5}>
              <ProductActiveStatusSection />
              <PriceSection />
              <Box mt={3}>
                <PricingSummary />
              </Box>
              <StockControlSection />
            </Grid>

            {/* Location Section */}
            {/* <Grid item xs={12} lg={7.5}>
              <ProductLocation />
            </Grid> */}

            {/* Stock Control Section */}
            {/* <Grid item xs={12} lg={4.5}></Grid> */}

            {/* Description Section */}
            <Grid item xs={12}>
              <DescriptionSection />
            </Grid>

            {/* Product Options Section */}
            <Grid item xs={12}>
              <ProductOptionsSections />
            </Grid>

            {/* Variants Section */}
            {/* {product?.attributes?.length !== 0 && (
                <Grid item xs={12} lg={12}>
                  <AddVariants />
                </Grid>
              )} */}

            {/* Product Media Section */}
            <Grid item xs={12}>
              <MediaSection />
            </Grid>

            {/* Product Delivery Section */}
            <Grid item xs={12} lg={7.5}>
              <DeliverySection />
            </Grid>

            {/* Product Service Section */}
            <Grid item xs={12} lg={4.5}>
              <ServiceSection />
            </Grid>

            {/* Action Buttons */}
            <Grid item xs={12}>
              {showErrorMessage && (
                <Typography color="error" fontSize={{ lg: "1.15rem" }} mb={1}>
                  Please fill out all highlighted fields
                </Typography>
              )}
              <Stack direction="row" gap={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={handleFormSubmit}
                  disabled={loading || !formik.dirty}
                  sx={{
                    fontWeight: "bold",
                    minWidth: { md: 100, xl: 250 },
                    height: { xs: 50, xl: 55 },
                  }}
                >
                  {loading ? "Submitting..." : "Submit"}
                </Button>
                {/* <Button
                      variant="outlined"
                      color="primary"
                      disabled
                      disableElevation
                      sx={{
                        fontWeight: "bold",
                        minWidth: { md: 100, lg: 175, xl: 250 },
                        height: { xs: 50, xl: 55 },
                      }}
                    >
                      Save as Draft
                    </Button> */}
                {showNextButton && (
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={handleClickNext}
                    endIcon={<NavigateNextIcon />}
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    Next
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
};
EditProductForm.displayName = "EditProductForm";

export default memo(EditProductForm);

const validationSchema = Yup.object().shape({
  // Basic Information Section
  name: Yup.string()
    .trim()
    .required("Required*")
    .min(10, "Name should be atleast 10 characters")
    .max(150, "Name is too long"),
  sku: Yup.string()
    .trim()
    .required("Required*")
    .min(3, "SKU is too short")
    .max(50, "SKU is too long"),
  category: Yup.object()
    .nullable()
    .test("not-null", "Required*", (value) => value !== null)
    .required("Required*"),
  hsnCode: Yup.string()
    .trim()
    .min(8, "HSN Code must be atleast 6 digits")
    .max(10, "HSN Code cannot exceed atleast 10 digits"),

  // Pricing Section
  price: Yup.number("Enter a valid number")
    .required("Required*")
    .moreThan(0, "Price must be greater than 0")
    .max(9999999, "Price is too high"),
  onSale: Yup.boolean(),
  salePrice: Yup.number().when("onSale", {
    is: (value) => value,
    then: Yup.number()
      .required("Required")
      .moreThan(0, "Sale price must be greater than 0")
      .max(999999, "Price is too high")
      .lessThan(
        Yup.ref("price"),
        "Sale price must be lower than the regular price"
      ),
    otherwise: Yup.number().notRequired().max(999999, "Price is too high"),
  }),

  // Location
  origin: Yup.string().required("Product origin is required"),
  region: Yup.array()
    .min(1, "Please select at least one sale region")
    .required("Region is required"),

  // Description Section
  description: Yup.string()
    .trim()
    .required("Required*")
    .min(80, "Description must be at least 80 characters long")
    .max(50000, "Description should not exceed 50000 characters"),
  // Stock Control Section
  quantity: Yup.number("Enter a valid number")
    .required("Required*")
    .integer("Must be an integer")
    .min(0, "Quantity must be greater or equal to 0")
    .max(9999, "Quantity is too high"),

  // Product Options Section
  attributes: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
      values: Yup.array()
        .of(Yup.string())
        .min(
          1,
          `Please select at least one option for the required attributes.`
        )
        .max(20, `You can add up to 20 options for the required attributes.`),
    })
  ),

  // Product Media Section
  mainImage: Yup.string().required("Required*"),
  gallery: Yup.array()
    .of(Yup.string())
    .max(6, "You can add up to 6 images only"),
  video: Yup.string(),

  // Product Delivery Section
  packageVolumeUnit: Yup.string(),
  packageLength: Yup.number().when("packageVolumeUnit", {
    is: (unit) => unit,
    then: Yup.number()
      .required("Required*")
      .moreThan(0, "Length should be greater than 0")
      .integer("Value must be an integer")
      .max(999, "Too long"),
    otherwise: Yup.number().notRequired(),
  }),
  packageWidth: Yup.number().when("packageVolumeUnit", {
    is: (unit) => unit,
    then: Yup.number()
      .required("Required*")
      .moreThan(0, "Width should be greater than 0")
      .integer("Value must be an integer")
      .max(999, "Too wide"),
    otherwise: Yup.number().notRequired(),
  }),
  packageHeight: Yup.number().when("packageVolumeUnit", {
    is: (unit) => unit,
    then: Yup.number()
      .required("Required*")
      .moreThan(0, "Height should be greater than 0")
      .integer("Value must be an integer")
      .max(999, "Too tall"),
    otherwise: Yup.number().notRequired(),
  }),
  packageWeight: Yup.number()
    .when("packageWeightUnit", {
      is: (unit) => unit === "g",
      then: Yup.number()
        .required("Required*")
        .moreThan(0, "Weight should be greater than 0")
        .integer("Value must be an integer")
        .max(1000, "For more than 1000 grams, please use the kg unit"),
      otherwise: Yup.number().notRequired(),
    })
    .when("packageWeightUnit", {
      is: (unit) => unit === "kg",
      then: Yup.number()
        .required("Required*")
        .moreThan(0, "Weight should be greater than 0")
        .max(99, "Too heavy"),
      otherwise: Yup.number().notRequired(),
    }),

  // Product Service Section
  warrantyType: Yup.string().required("Required*"),
  warrantyPeriod: Yup.string().when("warrantyType", {
    is: (type) => type !== "none",
    then: Yup.string().required(
      "Warranty period is required when a warranty type is selected"
    ),
    otherwise: Yup.string().notRequired(),
  }),
  warrantyTenure: Yup.number()
    .when(["warrantyType", "warrantyPeriod"], {
      is: (type, period) => type !== "none" && period === "day",
      then: Yup.number()
        .required("Warranty tenure is required for days")
        .moreThan(0, "Tenure must be at least 1 day")
        .integer("Warranty tenure must be an integer")
        .max(365, "Tenure cannot exceed 365 days"),
      otherwise: Yup.number().notRequired(),
    })
    .when(["warrantyType", "warrantyPeriod"], {
      is: (type, period) => type !== "none" && period === "month",
      then: Yup.number()
        .required("Warranty tenure is required for months")
        .moreThan(0, "Tenure must be at least 1 month")
        .integer("Warranty tenure must be an integer")
        .max(12, "Tenure cannot exceed 12 months"),
      otherwise: Yup.number().notRequired(),
    })
    .when(["warrantyType", "warrantyPeriod"], {
      is: (type, period) => type !== "none" && period === "year",
      then: Yup.number()
        .required("Warranty tenure is required for years")
        .moreThan(0, "Tenure must be at least 1 year")
        .integer("Warranty tenure must be an integer")
        .max(20, "Tenure cannot exceed 20 years"),
      otherwise: Yup.number().notRequired(),
    }),
});
