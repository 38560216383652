import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllTransactions, setVmtTab } from "store/slices/reportSlice";
import { FILTERS_BACKGROUND_COLOR } from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";
import ReportDataGrid from "./ReportDataGrid";

export default function VmtTabs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useQueryParams();
  const { loading, vmtTab } = useSelector((state) => state.reports);
  const userRole = useSelector((state) => state.auth?.userRole);

  const handleCSVClick = () => {
    dispatch(getAllTransactions(params));
  };

  const handleChange = useCallback(
    (_, newValue) => {
      dispatch(setVmtTab(newValue));
      navigate("/reports/vmt");
    },
    [dispatch, navigate]
  );

  return (
    <Box>
      <Box
        display="flex"
        pt={1}
        justifyContent={userRole === "admin" ? "space-between" : "end"}
        alignItems="center"
      >
        {userRole === "admin" && (
          <Box sx={containerStyles}>
            <Box
              sx={{
                backgroundColor: FILTERS_BACKGROUND_COLOR,
                marginBottom: { xs: 2, md: 0 },
              }}
            >
              <Box>
                <Tabs
                  value={vmtTab}
                  onChange={handleChange}
                  aria-label="return status filters"
                >
                  <Tab label="Transactions" value="TRANSACTIONS" />
                  <Tab label="Invoice Fees" value="INVOICED" />
                  <Tab label="Order Level Fees" value="ORDERSFEES" />
                </Tabs>
              </Box>
            </Box>
          </Box>
        )}
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          disabled={loading}
          endIcon={<CloudDownloadIcon />}
          onClick={handleCSVClick}
        >
          Export to CSV
        </Button>
      </Box>
      <Box mt={2}>
        <ReportDataGrid />
      </Box>
    </Box>
  );
}
const containerStyles = {
  display: { xs: "grid", md: "flex" },
  justifyContent: "space-between",
  alignItems: "baseline",
  "& .styled-button": {
    borderRadius: "1px",
    padding: "10px",
    fontSize: {
      md: "1rem",
      xs: "0.7rem",
    },
  },
  "& .MuiOutlinedInput-root": { borderRadius: "20px" },
  "& .MuiTab-root": { textTransform: "capitalize" },
};
