import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { memo, useState } from "react";
import { useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import useQueryParams from "utils/hooks/useQueryParams";

import { useEffect } from "react";

function FilterByPlatform() {
  const navigate = useNavigate();
  const params = useQueryParams();
  const loading = useSelector((state) => state.filters.loading);
  const [platformArray] = useState([
    { code: "all", label: "All" },
    { code: "aliExpress", label: "AliExpress" },
    { code: "bazaarGhar", label: "MySouq" },
    { code: "shopify", label: "Shopify" },
    { code: "ginkgo", label: "Ginkgo" },
  ]);
  const [selectedPlatform, setSelectedPlatform] = useState(null);

  useEffect(() => {
    const platformCode = params.platform;
    const matchedPlatform = platformArray.find(
      (platform) => platform.code === platformCode
    );

    if (matchedPlatform) {
      setSelectedPlatform(matchedPlatform);
    } else {
      setSelectedPlatform(platformArray[0]); // Default to "All"
    }
  }, [params, platformArray]);

  const handlePlatformChange = (newValue) => {
    let _params = { ...params };

    if (!newValue) {
      setSelectedPlatform(null);
      delete _params.platform;
    } else if (newValue.code === "all") {
      setSelectedPlatform(newValue);
      delete _params.platform;
    } else {
      setSelectedPlatform(newValue);
      _params.platform = newValue.code;
    }

    navigate({ search: createSearchParams(_params).toString() });
  };

  return (
    <Box display="flex" gap={1}>
      <Autocomplete
        sx={{ minWidth: 170, maxWidth: 180 }}
        size="small"
        value={selectedPlatform}
        onChange={(_, newValue) => handlePlatformChange(newValue)}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        noOptionsText="No Options Available"
        options={platformArray}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Platforms"
            fullWidth
            sx={{
              "& .MuiInputLabel-root": { fontSize: "1.063rem", height: 44 },
              "& .MuiInputBase-root": { height: 44 },
            }}
          />
        )}
      />
    </Box>
  );
}

export default memo(FilterByPlatform);
