import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useCallback, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { FILTER_TEXT_COLOR, FILTERS_BACKGROUND_COLOR } from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";

const paymentMethods = {
  COD: "cod",
  COD_WALLET: "cod_wallet",
  WALLET_CARD: "wallet_card",
  CARD: "card",
  WALLET: "wallet",
  MANUAL: "manual",
};

function PaymentFilter() {
  const params = useQueryParams();
  const navigate = useNavigate();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const paymentOptions = Object.keys(paymentMethods).map((key) => ({
    label: paymentMethods[key],
    value: key,
  }));

  const handleChange = useCallback(
    (_, newValue) => {
      let _params = { ...params };

      if (newValue?.label) _params.method = newValue?.label;
      else if (!newValue && _params?.method) {
        delete _params.method;
      }
      setSelectedPaymentMethod(newValue);
      navigate({
        search: createSearchParams(_params).toString(),
      });
    },
    [navigate, params]
  );

  return (
    <Autocomplete
      sx={{ minWidth: 170, maxWidth: 180 }}
      size="small"
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      options={paymentOptions}
      value={selectedPaymentMethod}
      noOptionsText="No Options Available"
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Payment Method"
          fullWidth
          sx={{
            "& .MuiInputLabel-root": {
              fontSize: "1.063rem",
              height: 44,
            },
            "& .MuiInputBase-root": {
              height: 44,
              backgroundColor: FILTERS_BACKGROUND_COLOR,
              cursor: "pointer",
            },
            input: {
              color: FILTER_TEXT_COLOR,
              "&::placeholder": {
                opacity: 1,
              },
            },
          }}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}

export default PaymentFilter;
